import React, { useState } from "react"
import styled from "styled-components"

import ThemeDefault from "../theme/ThemeDefault";

import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from 'react-player/lazy'

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
	background-color: ${(props) => props.bgColour};

	h2 {
		width: 100%;
		text-align: center;
		margin-bottom: 8px;
	}
	.section-text {
			width: 100%;
			text-align: center;
			margin-top: 16px;
	}
  .video,
	.image-wrapper {
		box-shadow: 0 8px 16px -8px rgba(0,0,0,0.5);
		border-radius: 2.4rem;
		overflow: hidden;
		cursor: pointer;
		position: relative;

		&:hover {
		& .playIcon {
				background: ${ThemeDefault.blue};
			}
		}
	}
  
	.lightBoxLink {
      cursor: pointer;

    .play-icon {
      display: inline-block;
      width: 50px;
      height: 50px;
      background: ${ThemeDefault.offWhite};
      position: relative;
      border-radius: 50%;
      margin-right: 8px;

      &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 24px;
        left: 58%;
        top: 50%;
        transform: translate( -50%, -50%);
        border-style: solid;
        border-color: transparent transparent transparent #202020;
        border-width: 12px 0px 12px 18px;
      }
    }
  }
  
  .loadedLightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.0);
    z-index: 9999999;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    transition: 400ms;
    cursor: pointer;

    &.open {
      background: rgba(0, 0, 0, 0.85);
    }

    & .closeButton {
      position: absolute;
      top: 16px;
      right: 16px;
      color: #000;
      background: #fff;
      line-height: 1;
      height: 34px;
      width: 34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      padding-top: 3px;
      font-weight: 700; 
    }
    & .lightboxInner {
      width: 90%;
      max-width: 1200px;
      max-height: 80vh;
      height: 80%;
      & > div {
        width: 100% !important;
        height: 100% !important;
        position: relative !important;
        & > div {
          position: absolute !important;

          & iframe {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }

	.playIconWrap {
		
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;

		& .playIcon {
      display: inline-block;
      width: 75px;
      height: 75px;
      background: #202020;
      position: absolute;
      border-radius: 50%;
      margin-right: 8px;
			left: 50%;
			top: 50%;
			transform: translate( -50%, -50%);
			z-index: 9;
			box-shadow: 0 8px 16px -8px rgba(0,0,0,0.5);
			transition: 300ms ease;


      &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 24px;
        left: 50%;
        top: 50%;
        transform: translate( -50%, -50%);
        border-style: solid;
        border-color: transparent transparent transparent ${ThemeDefault.offWhite};
        border-width: 12px 0px 12px 18px;
      }

			&:hover {
				background: ${ThemeDefault.blue};
			}
    }
	}
  .video > div{
    position: absolute;
  }
	.play-icon {
      display: inline-block;
      width: 50px;
      height: 50px;
      background: ${ThemeDefault.offWhite};
      position: relative;
      border-radius: 50%;
      margin-right: 8px;

		&:before {
			content: '';
			position: absolute;
			width: 18px;
			height: 24px;
			left: 58%;
			top: 50%;
			transform: translate( -50%, -50%);
			border-style: solid;
			border-color: transparent transparent transparent #202020;
			border-width: 12px 0px 12px 18px;
		}
	}

` 

const ImageVideoSection = ( data ) => {
  
	const [showVideo, setShowVideo] = useState(false)

  // const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
  // const [isLightBoxOpenClass, setIsLightBoxOpenClass] = useState('')

  // const handleKeyDown = (ev, index) => {
  //   // check keys if you want
  //   if (ev.keyCode === 13) {
  //     setIsLightBoxOpen(true)
  //   }

  // }

  // const openVidLightbox = () => {
  //   setIsLightBoxOpen(true)
    
  //   setTimeout(()=>{
  //     setIsLightBoxOpenClass('open')
  //   }, 300)

  //   document.querySelector('body').style.overflowY = 'hidden';
  // }
  // const closeVidLightbox = () => {
  //   setIsLightBoxOpen(false)
  //   setIsLightBoxOpenClass('close')
  //   document.querySelector('body').style.overflowY = 'auto';
  // }

  //console.log(data);
  const {childImageSharp} = data.data.image.imageFile;

  return (
    <SectionComponent key={`slider-${data.index}`} bgColour={data.data.sectionBgColour} >
      <div className="inner-div wrapper">
				{data.data.header && <h2>{data.data.header}</h2>}
				{ !showVideo ? <div className="image-wrapper" role="button" tabIndex="-1" onClick={() => {setShowVideo(true);}} onKeypress={() => {setShowVideo(true);}} >
					<GatsbyImage alt={data.data.header} image={childImageSharp.gatsbyImageData} />
					<div className="playIconWrap">
						<div className="playIcon"></div>
					</div>
				</div> :
        <div className="vidWrap">
          <ReactPlayer playsinline={true} playing={true} className="video" width={"100%"} url={ data.data.videoLink} config={{file:{attributes:{poster: childImageSharp.gatsbyImageData.images.fallback.src}}}} controls={true} style={{paddingBottom: "56.25%", position: "relative", width: "100%"}}/>
        </div>
				}
				{data.data.sectionText && <div className="section-text">{data.data.sectionText}</div>}
			</div>

    </SectionComponent>
  )
}

export default ImageVideoSection

