import React from "react"
import styled from "styled-components"

import ParseHTML from "../helpers/ParseHTML"

import { randomID } from "../helpers/helpers"

import ThemeDefault from "../theme/ThemeDefault";
import {device} from "../theme/MediaQueries"

import { GatsbyImage } from "gatsby-plugin-image"

import LoadedButton from "../elements/LoadedButton";
import { Link, navigate } from "gatsby";
import Tick from "../../assets/images/icons/tick.svg";

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  .col-1 {

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;

  }

  .col-2 {
    padding: 24px;

    @media ${device.MXxs} {
      padding: 0;
    }

    & .text-block {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

  }

  .image-wrapper {
    width: 100%;

    @media ${device.MXmd} {
      height: auto;
      margin-bottom: 16px;
      
      & .gatsby-image-wrapper {
        position: relative !important;
      }
    } 

  }

  .column-image .gatsby-image-wrapper {
    width: 100%;
    height: auto;
    object-fit: contain;
    position: relative; 
  }

  .c-reverse-m .col-1 {
    @media ${device.MXsm} {
      grid-row-start: 2;
    }
  }
  .learn-more {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    & a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: ${ThemeDefault.black}
    }
  }
  .rotate-45 {
    transform: rotate(45deg);
    margin-right: 8px;
  }

  .icon-title {
    display: flex;
    flex-direction: row;
    transform: translateX(-58px);
    align-items: center;
    margin-bottom: 8px;

    & .icon-wrapper {
      width: 50px;
      margin-right: 8px;
    }

    & h2 {
      line-height: 1.3;
      font-weight: 500;
    }
  }

  @media ${device.MXmd} {

    .icon-title {
      display: flex;
      transform: none;
      margin-bottom: 8px;

      & .icon-wrapper {
        width: 32px;
      }

    }

  }

  .feature-text-block {
    margin-bottom: 24px;
  }
  .features {
    margin-bottom: 32px;

    & .text {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      line-height: 1.3;
      margin-bottom: 8px;
    }
  }
  .tick {
    color: ${ThemeDefault.blue};
    width: 16px; 
    margin-right: 8px;
    flex-shrink: 0;
    flex-grow: 0;
  }

` 

const routeChange = (path) =>{  
  navigate(path);
}


const Chevron = (props) => {

  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.12 30.12">
      <path d="M15.75.94A15.06,15.06,0,1,0,30.81,16,15.06,15.06,0,0,0,15.75.94Zm0,29.12A14.06,14.06,0,1,1,29.81,16,14.05,14.05,0,0,1,15.75,30.06Zm8.13-22.9L15.75,15.3,7.61,7.16l-.71.71L15,16,6.9,24.14l.71.71,8.14-8.14,8.13,8.14.71-.71L16.45,16l8.14-8.13Z" transform="translate(-0.69 -0.94)"/>
    </svg>
  );
 }
 

const FeatureImageLeftContentRight = ( data ) => {
  
  //console.log(data);
  const {childImageSharp, publicURL} = data.data.image.imageFile;

  const titleIconChildImageSharp = data.data.titleIcon ? data.data.titleIcon.imageFile.childImageSharp : null;
  const titleIconPublicURL = data.data.titleIcon ? data.data.titleIcon.imageFile.publicURL : null;

  return (
    <SectionComponent key={`slider-${data.index}`} cssClass="alternate-rows" id={data.data.title.split(' ').join('-').toLowerCase()}>
      <div className="inner-div wrapper slider-post">
        <div className="c-columns-2 c-columns-gap-xs c-columns-m-1">
          <div className="col-1 c-relative column-image">
            <div className="image-wrapper">
              {
                childImageSharp ? 
                  <GatsbyImage alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} image={childImageSharp.gatsbyImageData} className={data.data.className} /> :
                  publicURL && <img alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} src={publicURL} className={data.data.className} /> 
              }
            </div>
          </div>
          <div className="col-2">
            <div className="text-block">
              <div className="icon-title">
                <div className="icon-wrapper">
                  {
                    titleIconChildImageSharp ? 
                      <GatsbyImage alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} image={titleIconChildImageSharp.gatsbyImageData} className={data.data.className} /> :
                      titleIconPublicURL && <img alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} src={titleIconPublicURL} className={data.data.className} /> 
                  }
                </div>
                <h2>{ data.data.title && ParseHTML(data.data.title) }</h2>
              </div>
              <div className="feature-text-block">
                <div className="text">{ data.data.featureText && ParseHTML(data.data.featureText) }</div>
              </div>
              <div className="features">
                { data.data.featurePoints.length && data.data.featurePoints.map((feature, index) => (
                  <div className="text" key={`tick-${index}-${randomID()}`}>
                    <Tick className="tick" />{ParseHTML(feature.featurePoint)}
                  </div>
                  ))
                }
              </div>
              {
                data.data.hasCtaButton && 
                <div className="cta">
                  <LoadedButton cssClass="blue" onClick={() => {routeChange(data.data.buttonLink)}} to={data.data.buttonLink}>{data.data.buttonText}</LoadedButton>
                </div>
              }
              {
                data.data.hasLearnMoreLink && 
                <div className="learn-more">
                  <Link to={data.data.learnMoreLink}><Chevron className={`icon rotate-45`} width={32} stroke={ThemeDefault.black} /> <div>Learn more about <strong>{data.data.title} Features</strong></div></Link>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </SectionComponent>
  )
}

export default FeatureImageLeftContentRight
