import React from "react"

// import LoadedButton from "../elements/LoadedButton";
import {Link} from 'gatsby'; //import navigate from gatsby
import ThemeDefault from "../theme/ThemeDefault";

import styled from "styled-components"
import LoadedImage from "../helpers/LoadedImage";

import Moment from 'react-moment';


const Card = styled.div.attrs((props) => ({
  className: props.cssClass,
}))`
  
 box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.5);
 
 & .image-wrap {

  & .gatsby-image-wrapper {
    width: 100%;
    padding-bottom: 70%;
    position: relative;

    div, picture, img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }


 }
 .card-content {
  padding: 24px;
 }

 .cats {
  margin-bottom: 16px;

  .term {
    background: ${ThemeDefault.blue};
    display: inline-block;
    padding: 4px;
    font-size: ${ThemeDefault.font__2};
    color: ${ThemeDefault.white};
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
 }

  .title {

    h3 {
      font-family: ${ThemeDefault.fontSecondary};
      font-size: ${ThemeDefault.font_4};
      font-weight: 400;
      letter-spacing: -1px;
      margin-bottom: 32px;
    }

  }

  .author {
    margin-bottom: 16px;
  }

  .button-wrap {
    margin-top: 8px;
    margin-bottom: 16px;

    .button {
      display: inline-block;
      padding: 8px 16px;
      border: 1px solid ${ThemeDefault.blue};
      color: ${ThemeDefault.blue};
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: ${ThemeDefault.font__2};
    }
  }
`

const BlogPostCard = ( props ) => {

  const blogPost = props.post.content
  const blogDate = blogPost.date;

  return (
    <Card cssClass="card">

      <div className="image-wrap">
        {blogPost.featuredImage && <LoadedImage altText={blogPost.featuredImage.node.altText} imgData={blogPost.featuredImage.node.imageFile} className="responsive-image" />}
      </div>
      <div className="card-content">
        <div className="cats">
          {blogPost.terms.nodes && blogPost.terms.nodes.map((term, index) => {
            return (<div className="term" key={`term-${term.id}`}>{term.name}</div>)
          })}
        </div>
        <div className="title">
          <h3>{blogPost.title}</h3>
        </div>
        <div className="author">
          By {blogPost.author.node.firstName} {blogPost.author.node.lastName} / <Moment format="MMM, D YYYY">{blogDate}</Moment>
        </div>
        <div className="button-wrap">
          <a href={blogPost.ExternalURL.externalUrl} target="_blank" rel="noreferrer">
            <div className="button open">
              Continue Reading
            </div>
          </a>
        </div>
      </div>
    </Card> 
  )
  
}

export default BlogPostCard;