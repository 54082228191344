import React from "react"
import styled from "styled-components"

import ThemeDefault from "../theme/ThemeDefault";

import {device} from "../theme/MediaQueries"

import BlogPostCard from "../Cards/BlogPostCard"

// import PlayButton from '../../assets/images/icons/play-button.svg'

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  .hero-inner {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;

      & .title-col {
        align-items: center;
      }
      & h1  {
        text-align: center;
        & *  {
          text-align: center;
        }
      }
    }

    .button-text {
      text-align: left;
      font-weight: 700;
      color: ${ThemeDefault.blue};

      @media ${device.MXmd} {
        width: 100%;
        margin-top: 8px;
      }
    }

  }
`


const BlogCardSection = ( data ) => {

  //console.log(data)
  
  return (
    <SectionComponent key={`hero-${data.index}`} cssClass="m-top" >
      <div className="inner-div wrapper hero-inner">
        <div className="c-columns-3 c-columns-m-1 c-columns-s-1 c-columns-gap-s" style={{width: "100%"}}>
          {data.data.blogContent.map((post, index) => {
            return (
              <BlogPostCard key={`blog-card-${index}`} post={post} />
            )
          })}
        </div>

      </div>
    </SectionComponent>
  )
}

export default BlogCardSection
