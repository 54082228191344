import React from "react"
import styled from "styled-components"

import {navigate} from 'gatsby'; //import navigate from gatsby

import LoadedButton from "../elements/LoadedButton";

import { GatsbyImage } from "gatsby-plugin-image"

import ThemeDefault from "../theme/ThemeDefault";
import {device} from "../theme/MediaQueries"
import ParseHTML, { ParseAndRemoveTags } from "../helpers/ParseHTML";

// import PlayButton from '../../assets/images/icons/play-button.svg'

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

  .hero-inner {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;

      & .title-col {
        align-items: center;
      }
      & h1  {
        text-align: center;
        & *  {
          text-align: center;
        }
      }
    }

    h1 {
      font-size:  ${ThemeDefault.font_6};
      font-family: ${ThemeDefault.fontPrimary};
      line-height: 1.2;
      margin-bottom: 32px;
      text-align: left;
      font-weight: 500;
      letter-spacing: -1px;
      p {
        margin-bottom: 20px;
      }
    }

    .subtitle {
      font-size: ${ThemeDefault.font__1};
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      margin-bottom: 16px;
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }

    .title-col {
      padding: 24px 24px 24px 0 !important;
      width: 50%;

      @media ${device.MXmd} {
        padding: 24px 0;
        width: 50%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
      @media ${device.MXxs} {
        width: 100%;
      }
    }

    .hero-text {
      text-align: left;
      margin-bottom: 32px;
      font-size: var(--font-2);
      padding-right: 64px;

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .button-text {
      text-align: left;
      font-weight: 700;
      color: ${ThemeDefault.blue};

      @media ${device.MXmd} {
        width: 100%;
        margin-top: 8px;
      }
    }

    .hero-button-row {
      flex-direction: row;
      display: flex;
      align-items: center;
      
      @media ${device.MXmd} {
        flex-wrap: wrap;
      }
      
      p {
        margin: 0;
      }
      
    }

    .image-col {
      width: 50%;
      padding: 16px 48px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: visible !important;
      
      div {
        overflow: visible !important;
      }

      @media ${device.MXmd} {
        width: 50%;
      }

      @media ${device.MXsm} {
        width: 100%;
      }

      @media ${device.MXxs} {
        width: 100%;
      }

      .gatsby-image-wrapper {
        transform: rotate(-4deg);
        box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.4);
      }

      & .image-inner {
        position: relative;
        width: 100%;
        padding-bottom: 100%;

        &>div {
          position: absolute;
        }
        .player {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`


const CTAWithImage = ( data ) => {

  const {childImageSharp, publicURL} = data.data.image.imageFile;

  const routeChange = (path) =>{  
    navigate(path);
  }
  
  return (
    <SectionComponent key={`hero-${data.index}`} cssClass="m-top" bgColour={data.data.sectionBgColour}>
      <div className="inner-div wrapper hero-inner">
        <div className="title-col">
          
          <div className="hero-title">
            <div className="subtitle">{data.data.subtitle && ParseHTML(data.data.subtitle)}</div>
            <h1>{data.data.title && ParseAndRemoveTags(data.data.title)}</h1>
          </div>
          <div className="hero-button-row">
            <LoadedButton tabIndex={0} cssClass="black hero" onClick={() => {routeChange(data.data.button1Link)}} to={data.data.button1Link}>{data.data.button1Text}</LoadedButton>
          </div>
        
        </div>
        <div className="image-col">
          {
            childImageSharp ? 
              <GatsbyImage alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} image={childImageSharp.gatsbyImageData} className={data.data.className} /> :
              <img alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} src={publicURL} className={data.data.className} /> 
          }
        </div>
      </div>
    </SectionComponent>
  )
}

export default CTAWithImage
