import React from "react"
import styled from "styled-components"

import ThemeDefault from "../theme/ThemeDefault";
import {device} from "../theme/MediaQueries"
import ParseHTML from "../helpers/ParseHTML";

import HubspotForms from "../Hubspot/HubspotForms";

// import PlayButton from '../../assets/images/icons/play-button.svg'

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

  .hero-inner {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;

      & .title-col {
        align-items: center;
      }
      & h1  {
        text-align: center;
        & *  {
          text-align: center;
        }
      }
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 32px;
      text-align: left;
      font-weight: 500;
      
      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }
    
    .form-col {
      width: 60%;
      margin: 0 auto;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: stretch;
      text-align: center;
      

      .form-title {
        font-size:  ${ThemeDefault.font_5};
        font-family: ${ThemeDefault.fontSecondary};
        line-height: 1.4;
      }


      @media ${device.MXmd} {
        width: 50%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
      @media ${device.MXxs} {
        width: 100%;
      }
    }

    .title-col {
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
      width: 60%;

      @media ${device.MXmd} {
        padding: 24px 0;
        width: 50%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
      @media ${device.MXxs} {
        width: 100%;
      }
    }

    .hero-text {
      text-align: left;
      margin-bottom: 32px;
      font-size: var(--font-2);
      padding-right: 64px;

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .form-wrap {
      padding: 20px;
      background: ${ThemeDefault.white};
    }
  }


`


const HubspotFormSection = ( data ) => {

  //console.log(data)

  return (
    <SectionComponent key={`hero-${data.index}`} cssClass="m-top" bgColour={data.data.sectionBgColour}>
      <div className="inner-div wrapper hero-inner">

        <div className="form-col" >
        
          <div className="form-wrap" >
            <div className="form-title">{data.data.formtitle && ParseHTML(data.data.formtitle)}</div>
            <div className="form-text">{data.data.formtext && ParseHTML(data.data.formtext)}</div>
            <HubspotForms formID={data.data.form} blockID={`form-${data.attributes.id}`} />
          </div>

        </div>
      </div>
    </SectionComponent>
  )
}

export default HubspotFormSection
