import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ThemeDefault from "../theme/ThemeDefault";

import SwiperCore, { Navigation, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';

import LeftArrow from '../../assets/images/icons/left-arrow.svg'
import RightArrow from '../../assets/images/icons/right-arrow.svg'

import {device} from "../theme/MediaQueries"

import LoadedImage from "../helpers/LoadedImage"
import ParseHTML from "../helpers/ParseHTML";

SwiperCore.use([Navigation, Mousewheel]);

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  h2 {
    font-family: ${ThemeDefault.fontSecondary};
    font-weight: 400;
  }
  
  .slider-wrapper {
    width: 100%;
    overflow: hidden !important; 
    flex-direction: row !important;
    align-items: stretch !important;
    
    & .swiper-slide {
      height: auto !important;
      flex-direction: row !important;
      align-items: stretch !important;

      & a {
        height: 100% !important;
        display: flex !important;
        flex-direction: column !important;

        & .text-outside {
          display: flex !important;
          flex-direction: column !important;
          justify-content: space-between !important;
          height: 100%;
        }
      }
    } 
  }

  .col-1 {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 32px;

    @media ${device.MXsm} {
      padding-right: 0;
    }

  }

  .col-2 {

  }

  .arrow-row {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: justify;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 24px;

    svg {
      pointer-events: none;
      padding: 1px;
    }
  }

  .tall-image {
    width: 100%;
    padding-bottom: 60%;
    position: relative;
    top: 0px;
    left: 0px;
    max-width: 250px;
    max-height: 375px;
    
    & .gatsby-image-wrapper-constrained,
    & img {
      max-width: 250px;
      max-height: 375px;
      position: absolute !important;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain !important;
      object-position: center;
    }
    & .card-title-wrapper {
      background-image: linear-gradient(rgba(22,20,20,0),rgba(22,20,20,0.92));
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 1;
      background-blend-mode: multiply;
      width: 100%;

      & .card-title {
        padding: 8px;
        padding-top: 24px;
        color: #eaeaea;
        font-weight: 500;
        margin: 0;
        font-family: ${ThemeDefault.fontPrimary};
        font-size: clamp(1.8rem,calc(1.8rem + ((1vw - 0.48rem) * 0.5)),2.2rem);
        line-height: clamp(1.8rem,calc(1.8rem + ((1vw - 0.48rem) * 0.5)),2.2rem);
      }

    }
    
  }
`

const ArrowWrap = styled.div`
  width: 40px;
  height: 40px;
  padding: 4px;
  cursor: pointer;
`

const SwiperComponent = (props) => {
  const { slides, index } = props;

  return (
    <>
      <Swiper
        freeMode={true} 
        spaceBetween={8}
        slidesPerView={1.5}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        navigation={{
          nextEl: `.swiper-next-${index}`,
          prevEl: `.swiper-prev-${index}`,
        }}
        breakpoints={{
          "768": {
            "slidesPerView": 2.5,
            "spaceBetween": 16
          },
          "992": {
            "slidesPerView": 3.5,
            "spaceBetween": 24
          }
        }}
        mousewheel={{
          forceToAxis: true,
          invert: false,
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide className="slider-card" key={`card-${index}`}>
            <LoadedLink external={slide.content.ExternalURL.externalUrl} to={slide.content.uri} >
              <div className="tall-image">
                {slide.content.featuredImage && <LoadedImage altText={slide.content.featuredImage.node.altText} imgData={slide.content.featuredImage.node.imageFile} className="responsive-image" />}
              </div>
            </LoadedLink>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="arrow-row">
        <ArrowWrap className={`swiper-prev-${index}`}>
          <LeftArrow  />
        </ArrowWrap>
        <ArrowWrap className={`swiper-next-${index}`}>
          <RightArrow  />
        </ArrowWrap>
      </div>
    </>
  );

}

const SliderPost = ( data ) => {

  // console.log(data.data.slides);
  
  return (
    <SectionComponent key={`slider-${data.index}`} cssClass={data.StrategySessionNext ? 'section-strategy-next' : 'section-bottom'}>
      <div className="inner-div wrapper slider-post">
        <div className="c-columns-3 c-columns-gap-xs c-columns-l-1">
          <div className="col-1 c-relative">
            <h2>{data.data.title}</h2>
            <div className="ct-text-block">
              <div className="text">{ data.data.text && ParseHTML(data.data.text) }</div>
            </div>
          </div>
          <div className="col-2 c-col-span-2 slider-wrapper">
            <div >
              <SwiperComponent slides={data.data.slides} index={data.index} />
            </div>
            
          </div>
        </div>
      </div>
    </SectionComponent>
  )
}

export default SliderPost

const LoadedLink = (props) => {
  const {externalUrl, to, children} = props

  if (externalUrl) {
    return <a href={externalUrl} target="_blank" rel="noreferrer" >{children}</a>
  }else{
    return <Link to={to}>{children}</Link>
  }
  
}