import React from "react"
import styled from "styled-components"

import {navigate} from 'gatsby'; //import navigate from gatsby

import LoadedButton from "../elements/LoadedButton";

import ThemeDefault from "../theme/ThemeDefault";
import {device} from "../theme/MediaQueries"
// import {debounce} from "../helpers/helpers"

import { GatsbyImage } from "gatsby-plugin-image"
import ParseHTML, { ParseAndRemoveTags } from "../helpers/ParseHTML";

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};
  margin-top: 4px;

  .hero-inner {
    
    &.inner-div {
      padding: 0 !important;
      padding-bottom: 0 !important;

      & .gatsby-image-wrapper {
        height: 100%;
      }
    }

    flex-direction: row;
    display: flex;
    align-items: stretch;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 16px;
      text-align: left;
      font-weight: 500;

      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }
    
    .spacer {
      margin-bottom: 64px;

      @media ${device.MXmd} {
        margin-bottom: 32px;
      }
    }

    .title-col {
      padding-top: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      padding-left: calc(24px + 24px);
      padding-right: calc(24px + 24px);
      padding-bottom: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      width: 50%;

      @media ${device.MXmd} {
        padding: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px) 24px;
        width: 60%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
    }

    .hero-text {
      text-align: left;
      margin-bottom: 32px;
      font-size: var(--font-2);
      padding-right: 64px;


      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .button-text {
      text-align: left;
      font-weight: 700;
      color: ${ThemeDefault.black};
      /* color: ${ThemeDefault.blue}; */

      @media ${device.MXmd} {
        width: 100%;
        margin-top: 8px;
      }
    }

    .hero-button-row {
      flex-direction: row;
      display: flex;
      align-items: center;
      
      @media ${device.MXmd} {
        flex-wrap: wrap;
      }
      
      p {
        margin: 0;
      }
      
    }

    .image-col {
      width: 50%;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      
			& picture,
			& img {
				object-fit: ${props => props.imageColContain ? 'contain !important' : 'cover !important'}
			}

      @media ${device.MXmd} {
        width: 40%;
      }

      @media ${device.MXsm} {
        width: 100%;
      }

      @media ${device.MXxs} {
        width: 100%;
      }
      
    }

  }
`

const HeroHalfHalf = ( data ) => {
  
  const routeChange = (path) =>{  
    navigate(path);
  }

  //console.log(data);
  const {childImageSharp, publicURL} = data.data.image.imageFile;


  return (
    <SectionComponent key={`hero-${data.index}`} bgColour={data.data.sectionBgColour} imageColContain={data.data.imageContain} cssClass={data.StrategySessionNext && 'section-strategy-next' }> 
      <div className="inner-div wrapper hero-inner">
        <div className="title-col">

          { data.data.title && <div className="hero-title"><h1>{ParseAndRemoveTags(data.data.title)}</h1></div> }
          { data.data.text ? <div className="hero-text">{ParseHTML(data.data.text)}</div> : <div className="spacer" ></div> }
          { data.data.button1Link && <div className="hero-button-row">
            <LoadedButton cssClass="blue hero" onClick={() => {routeChange(data.data.button1Link)}} to={data.data.button1Link}>{data.data.button1Text}</LoadedButton>
            <LoadedButton cssClass="open black hero" onClick={() => {routeChange(data.data.button2Link)}} to={data.data.button2Link}>{data.data.button2Text}</LoadedButton>
            <div className="button-text">{ data.data.buttonWrapText && ParseHTML(data.data.buttonWrapText) }</div>
          </div> }
        </div>
        <div className="image-col">
          {
            childImageSharp ? 
              <GatsbyImage alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} image={childImageSharp.gatsbyImageData} className={data.data.className} /> :
              <img alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} src={publicURL} className={data.data.className} /> 
          }
        </div>
      </div>
    </SectionComponent>
  )
}

export default HeroHalfHalf
