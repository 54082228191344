import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ThemeDefault from "../theme/ThemeDefault";

import SwiperCore, { Navigation, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import LeftArrow from '../../assets/images/icons/left-arrow.svg'
import RightArrow from '../../assets/images/icons/right-arrow.svg'

import {device} from "../theme/MediaQueries"
    
import LoadedImage from "../helpers/LoadedImage"

import ParseHTML from "../helpers/ParseHTML"

SwiperCore.use([Navigation, Mousewheel]);

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  h2 {
    font-family: ${ThemeDefault.fontSecondary};
    font-weight: 400;
  }
  
  .slider-wrapper {
    width: 100%;
    overflow: hidden !important; 
    flex-direction: row !important;
    align-items: stretch !important;
    
    & .swiper-slide {
      height: auto !important;
      flex-direction: row !important;
      align-items: stretch !important;

      & a {
        height: 100% !important;
        display: flex !important;
        flex-direction: column !important;

        & .text-outside {
          display: flex !important;
          flex-direction: column !important;
          justify-content: space-between !important;
          height: 100%;
        }
      }
    } 
  }

  .col-1 {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 32px;

    @media ${device.MXsm} {
      padding-right: 0;
    }
  }

  .col-2 {

  }

  .arrow-row {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: justify;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 24px;

    svg {
      pointer-events: none;
      padding: 1px;
    }
  }

  .short-image,
  .tall-image {
    width: 100%;
    padding-bottom: 150%;
    position: relative;
    top: 0px;
    left: 0px;
    max-width: 250px;
    max-height: 375px;
    
    & .gatsby-image-wrapper-constrained,
    & > img {
      max-width: 250px;
      max-height: 375px;
      position: absolute !important;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
    }

    &.features {
      & .card-title-wrapper {
        background: none;
        text-align: center;
        height: 40%;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .card-title {
          padding: 0;
          margin: 0;
          line-height: 1 !important;
          font-size: var(--font-3);

          @media ${device.MXmd} {
            font-size: var(--font-4);
          }
          @media ${device.MXsm} {
            font-size: var(--font-5);
          }
          @media ${device.MXsm} {
            font-size: var(--font-5);
          }

        }

      }
    }
  }


  .loadedCard {
    & .tall-image,
    & .short-image {
      overflow: hidden;
    }

    & .responsive-image {
      left: 50%;
      top: 50%;
      transform: translate( -50%, -50%) scale(1);
      transition: 600ms;
      transition-timing-function: cubic-bezier(.49,.01,.51,1);
    }
    &:hover {
      & .responsive-image {
        transform: translate( -50%, -50%) scale(1.1);
      }
    }

    &.text-inside {
      & .card-title-wrapper {
        background-image: linear-gradient(rgba(22,20,20,0) 0%,rgba(22,20,20,0.92) 50%);
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 99;
        background-blend-mode: multiply;
        width: 100%;
        
        & .card-title {
          padding: 8px;
          padding-top: 24px;
          color: ${ThemeDefault.white};
          font-weight: 500;
          margin: 0;
          line-height: 1.2;
          font-family: ${ThemeDefault.fontPrimary};
          font-size: clamp(1.8rem, calc(1.8rem + ((1vw - 0.48rem) * 0.5)), 2.2rem);

          &.has-sub {
            padding-bottom: 0;
          }
        }

        & .sub-rows {
          padding: 0 8px 8px 8px;
          color: ${ThemeDefault.white};
          font-size: clamp(1rem, calc(1rem + ((1vw - 0.48rem) * 0.3922)), 1.4rem);
          line-height: 1.2;
        }
      }
    }
    &.text-outside {
      & .card-title-wrapper {
        position: relative;
        background: none;

        & .card-title {
          color: ${ThemeDefault.black};
          font-weight: 500;
          margin: 0;
          margin-top: 16px;
          line-height: 1.2;
          font-family: ${ThemeDefault.fontPrimary};
          font-size: clamp(1.6rem, calc(1.6rem + ((1vw - 0.48rem) * 0.1961)), 1.8rem);
        }       
      }
    }

    .loop-description {
      color: ${ThemeDefault.black};
      font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.2941)), 1.5rem);
      margin-top: 4px;
      line-height: 1.4
    }

    .readMore {
      text-align: left;
      margin-top: 24px;
      font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.2941)), 1.5rem);
    }
  }
  
  .short-image {
    padding-bottom: 65%;
  }

  .slider-card {
    max-width: calc((1200px * 0.66) / 3.4);
    margin-right: 16px;
  }
`

const ArrowWrap = styled.div`
  width: 40px;
  height: 40px;
  padding: 4px;
  cursor: pointer;
`

const SwiperComponent = (props) => {
  const { slides, index, sliderStyle } = props;

  return (
    <>
      <Swiper
        // freeMode={true} 
        spaceBetween={8}
        slidesPerView={1.5}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        navigation={{
          nextEl: `.swiper-next-${index}`,
          prevEl: `.swiper-prev-${index}`,
        }}
        breakpoints={{
          "480": {
            "slidesPerView": 1.5,
            "spaceBetween": 16
          },
          "768": {
            "slidesPerView": 2.5,
            "spaceBetween": 16
          },
          "992": {
            "slidesPerView": 3.5,
            "spaceBetween": 24
          }
        }}
        mousewheel={{
          forceToAxis: true,
          invert: false,
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
      >  
        {slides.map((slide, index) => {
            
          const linkToUse = (slide.content.featuresLinks && slide.content.featuresLinks.internalLink) ? slide.content.featuresLinks.internalLink : slide.content.uri;

          return (
          <SwiperSlide className={`slider-card ${slide.content.__typename}`} key={`card-${index}`}>
            {(slide.content.ExternalURL) ?
              <a href={slide.content.ExternalURL.externalUrl} target="_blank" rel="noreferrer" >
                <LoadedCard sliderStyle={sliderStyle} slide={slide} />
              </a>
            :
            <Link to={linkToUse} >
              <LoadedCard sliderStyle={sliderStyle} slide={slide} />
            </Link>
            }
          </SwiperSlide>
        )})}
      </Swiper>
      <div className="arrow-row">
        <ArrowWrap className={`swiper-prev-${index}`}>
          <LeftArrow  />
        </ArrowWrap>
        <ArrowWrap className={`swiper-next-${index}`}>
          <RightArrow  />
        </ArrowWrap>
      </div>
    </>
  );

}

const LoadedCard = ( props ) => {
  const { slide, sliderStyle } = props

  if (sliderStyle === 'textInsideImage') {
    return (
      <div className="loadedCard text-inside">
        <div className={`tall-image ${slide.content.fieldName ? slide.content.fieldName.fieldGroupName : ''}`}>
          {slide.content.featuredImage && <LoadedImage altText={slide.content.featuredImage.node.altText} imgData={slide.content.featuredImage.node.imageFile} className="responsive-image" />}
          <div className="card-title-wrapper">
            <h3 className={`card-title ${(slide.content.metaContent && slide.content.metaContent.subHeader1 !== '') && `has-sub`}`}>{slide.content.title}</h3>
            {(slide.content.metaContent && slide.content.metaContent.subHeader1 !== '') && 
              <div className="sub-rows">
                <div className="sub-row-1">{slide.content.metaContent.subHeader1}</div>
                {slide.content.metaContent.subHeader2 !== "" && <div className="sub-row-2">{slide.content.metaContent.subHeader2}</div>}
              </div>
            }
          </div>
        </div>
      </div>
    )
  }else{
    return (
      <div className="loadedCard text-outside">
        <div>
          <div className={`short-image`}>
            {slide.content.featuredImage && <LoadedImage altText={slide.content.featuredImage.node.altText} imgData={slide.content.featuredImage.node.imageFile} className="responsive-image" />}
          </div>
          <div className="card-title-wrapper">
            <h3 className={`card-title ${(slide.content.metaContent && slide.content.metaContent.subHeader1 !== '') && `has-sub`}`}>{slide.content.title}</h3>
              {(slide.content.metaContent && (slide.content.metaContent.subHeader1 !== null && slide.content.metaContent.subHeader1 !== '')) && 
                <div className="sub-rows">
                  <div className="sub-row-1">{slide.content.metaContent.subHeader1}</div>
                  {(slide.content.metaContent.subHeader2 !== null && slide.content.metaContent.subHeader2 !== "") && <div className="sub-row-2">{slide.content.metaContent.subHeader2}</div>}
                </div>
              }
              {(slide.content.metaContent && (slide.content.metaContent.loopDescription !== null && slide.content.metaContent.loopDescription !== '')) && 
                <div className="loop-description">{slide.content.metaContent.loopDescription && ParseHTML(slide.content.metaContent.loopDescription)}</div>
              }  
          </div>
        </div>
        <div className="readMore">Read More &rarr;</div>          
      </div>
    )
  }
}


const SliderPost = ( data ) => {
  
  //console.log(data);

  return (
    <SectionComponent key={`slider-${data.index}`} cssClass={data.StrategySessionNext ? 'section-strategy-next' : 'section-bottom'}>
      <div className="inner-div wrapper slider-post">
        <div className="c-columns-3 c-columns-gap-xs c-columns-l-1">
          <div className="col-1 c-relative">
            <h2>{data.data.title}</h2>
            <div className="ct-text-block">
              <div className="text">{ data.data.text && ParseHTML(data.data.text) }</div>
            </div>
          </div>
          <div className="col-2 c-col-span-2 slider-wrapper">
            <div>
              <SwiperComponent slides={data.data.slides} index={data.index} sliderStyle={data.data.sliderStyle} />
            </div>
            
          </div>
        </div>
      </div>
    </SectionComponent>
  )
}

export default SliderPost
