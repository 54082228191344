import React from "react"
import styled from "styled-components"

import {navigate} from 'gatsby'; //import navigate from gatsby

import LoadedButton from "../elements/LoadedButton";

import ThemeDefault from "../theme/ThemeDefault";
import {device} from "../theme/MediaQueries"
// import {debounce} from "../helpers/helpers"

import { GatsbyImage } from "gatsby-plugin-image"
import ParseHTML, { ParseAndRemoveTags } from "../helpers/ParseHTML";

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};
  margin-top: 4px;

	.grid--2 {
		display: grid;
		grid-template-columns: 1.2fr 0.8fr;

		@media ${device.MXmd} {
			grid-template-columns: 1fr;
		}

	}

	.title h2 {
		font-size: 2rem;
	}
	
	.col-1 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.col {
		padding-top: calc(24px + 24px);
		padding-left: calc(24px + 24px);
		padding-right: calc(24px + 24px);
		padding-bottom: calc(24px + 24px);
		width: 100%;

		@media ${device.MXmd} {
			padding: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px) 24px;
			width: 100%;
		}
		@media ${device.MXsm} {
			width: 100%;
		}
	
	}

	ul,
	ul li {
		list-style: none;
	}

	.box-col ul, 
	.box-col li, 
	.col-1 ul,
	.col-1 li {
		margin: 0;
		padding: 0;
	}

	.hero-button-row {


		@media ${device.MXlg} {
			margin-top: 2.4rem;
		}
	}

	.col-1 li:before {
		content: "+";
		margin: 0 1em 0 0 ;
	}
	.col-1 li {
		margin-top: 0.8rem;
	}

	.box-col h1, 
	.box-col h2, 
	.box-col ul, 
	.box-col li {
		color: ${ThemeDefault.white};
	}
	
	.box-col li {
		position: relative;
		margin-top: 1.6rem;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: -0.8rem;
			width: 100%;
			height: 1px;
			background: ${ThemeDefault.white};
		}
	}

	& a {
		color: ${ThemeDefault.black};
	}
	
	.box-title,
	.box-title h2 {
		color: ${ThemeDefault.black};
		font-size: 1.6rem;
	}

	.box-text {
		font-family: ${ThemeDefault.fontSecondary};
		font-size: ${ThemeDefault.font_3};
		line-height: 1.2;
		margin: 0 auto;
		width: 80%;
	}
	
	.image-wrapper {
		width: 70%;
		margin: 0 auto;
		margin-top: 0px;
		margin-bottom: 0px;
		margin-top: 1.6rem;
		box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.5);
		margin-bottom: 2.4rem;
	}

	.col-1 {
		justify-content: center;
		display: flex;
		flex-direction: column;
	}
`

const FindOutMore = ( data ) => {

  const routeChange = (path) =>{  
    navigate(path);
  }

  const {childImageSharp, publicURL} = data.data.image.imageFile;

  return (
    <SectionComponent key={`hero-${data.index}`} imageColContain={data.data.imageContain} > 
      <div className="inner-div wrapper hero-inner">
				<div className="grid--2">
					<div className="col col-1">

						<div className="col-inner plus-list">
						{ data.data.text && <div className="">{ParseHTML(data.data.text)}</div> }
						</div>
					
					</div>

					<div className="col box-col" style={{textAlign: "center"}}>
						<a href={data.data.boxLink} target="_blank">
							<div className="col-inner" style={{backgroundColor: data.data.boxColour, padding: '2.4rem'}}>
								{ data.data.boxTitle && <div className="box-title"><h2>{ParseAndRemoveTags(data.data.boxTitle)}</h2></div> }
								{ data.data.boxText && <div className="box-text">{ParseAndRemoveTags(data.data.boxText)}</div> }

								<div className="image-wrapper">
									{
										childImageSharp ? 
											<GatsbyImage alt={data.data.altText ? data.data.altText : data.data.boxTitle.replace(/(<([^>]+)>)/gi, "")} image={childImageSharp.gatsbyImageData} className={data.data.className} /> :
											publicURL && <img alt={data.data.altText ? data.data.altText : data.data.boxTitle.replace(/(<([^>]+)>)/gi, "")} src={publicURL} className={data.data.className} /> 
									}
								</div>
							</div>
						</a>
					</div>

				</div>

      </div>
    </SectionComponent>
  )
}

export default FindOutMore
