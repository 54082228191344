import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Lottie from 'lottie-react-web'

import {navigate} from 'gatsby'; //import navigate from gatsby

import LoadedButton from "../elements/LoadedButton";

import ThemeDefault from "../theme/ThemeDefault";
import {device} from "../theme/MediaQueries"
// import {debounce} from "../helpers/helpers"

import MobileLottie from '../../assets/lottie/mobile.json'
import DesktopLottie from '../../assets/lottie/hero.json'

import ParseHTML, { ParseAndRemoveTags } from "../helpers/ParseHTML";

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  .hero-inner {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 16px;
      text-align: left;
      font-weight: 500;

      @media ${device.MXmd} {
        font-size: ${ThemeDefault.font_6}
      }
      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }

    .title-col {
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
      width: 55%;

      @media ${device.MXmd} {
        padding: 24px 0;
        width: 75%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
    }

    .hero-text {
      text-align: left;
      margin-bottom: 32px;
      font-size: var(--font-2);
      padding-right: 64px;

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .button-text {
      text-align: left;
      font-weight: 700;
      color: ${ThemeDefault.blue};

      @media ${device.MXmd} {
        width: 100%;
        margin-top: 8px;
      }
    }

    .hero-button-row {
      flex-direction: row;
      display: flex;
      align-items: center;
      
      @media ${device.MXmd} {
        flex-wrap: wrap;
      }
      
      p {
        margin: 0;
      }
      
    }

    .lottie-col {
      width: 45%;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      
      @media ${device.MXmd} {
        width: 35%;
      }

      @media ${device.MXsm} {
        width: 55%;
      }

      @media ${device.MXxs} {
        width: 75%;
      }
      

      & .lottie-inner {
        position: relative;
        width: 100%;
        padding-bottom: 100%;

        &>div {
          position: absolute;
        }
        .player {
          width: 100%;
          height: 100%;
        }
      }
    }

  }
`

const LottieComp = (props) => {
  const [lottieJSON, setLottieJSON] = useState(DesktopLottie)

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setLottieJSON(MobileLottie)
    } else {
      setLottieJSON(DesktopLottie)
    }
  }, [])

  return (<Lottie
    options={{
      // path: lottieJSON,
      animationData: lottieJSON,
      loop: false,
      renderer: 'svg',
    } 
  }></Lottie>);
}

const Hero = ( data ) => {
  
  const routeChange = (path) =>{  
    navigate(path);
  }

  return (
    <SectionComponent key={`hero-${data.index}`} cssClass={data.StrategySessionNext ? 'section-strategy-next' : 'section-bottom'}>
      <div className="inner-div wrapper hero-inner">
        <div className="title-col">

          <div className="hero-title"><h1>{ data.data.title && ParseAndRemoveTags(data.data.title) }</h1></div>
          <div className="hero-text">{ data.data.text && ParseHTML(data.data.text) }</div>
          <div className="hero-button-row">
            <LoadedButton cssClass="blue hero" onClick={() => {routeChange(data.data.button1Link)}} to={data.data.button1Link}>{data.data.button1Text}</LoadedButton>
            <LoadedButton cssClass="open blue hero" onClick={() => {routeChange(data.data.button2Link)}} to={data.data.button2Link}>{data.data.button2Text}</LoadedButton>
            <div className="button-text">{ data.data.buttonWrapText && ParseHTML(data.data.buttonWrapText) }</div>
          </div>
        </div>
        <div className="lottie-col">
          <div className="lottie-inner">
            <LottieComp data={data.data} />
          </div>
        </div>
      </div>
    </SectionComponent>
  )
}

export default Hero
