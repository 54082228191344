import React from "react"
import styled from "styled-components"

import LoadedButton from "../elements/LoadedButton";
import {navigate} from 'gatsby'; //import navigate from gatsby
import {device} from "../theme/MediaQueries"

import LoadedImage from "../helpers/LoadedImage";
import ParseHTML from "../helpers/ParseHTML";

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  position: relative;
  margin-top: -2px;
  margin-bottom: 64px;
  
	@media ${device.MXmd} {
		overflow: hidden;
	}

  &:before {
    content: "";
    position: absolute;
    /* background-color: #a7cafc; */
    background: var(--blueLight);
    /* background: linear-gradient(0deg, var(--pink) 0%, var(--pink) 33%, var(--tan) 33%, var(--tan) 66%, var(--blueLight) 66%, var(--blueLight) 100%);  */
    height: 100%;
    width: 120vw;
    /* transform: skewY(-1.5deg); */
  }
  
  & .inner-div {
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(5,minmax(100px,1fr));
    grid-column-gap: 32px;
    grid-row-gap: 20px;

    @media ${device.MXmd} {
      display: flex;
      flex-direction: column;
    }

    & .col-1, & .col-2 {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
      grid-column: span 2;
    }
    & .col-1 {
      grid-column: span 3;
    }
    & .col-2 {
      z-index: 6;
      @media ${device.MXmd} {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        align-items: center;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
    }
    .image-wrap {
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: -180px;
      position: relative;
      margin-top: -100px;

      @media ${device.MXmd} {
        margin: 0 auto;
        width: 60%;
        margin-bottom: 32px;
      }

      @media ${device.MXsm} {
        width: 90%;
        margin-bottom: 16px;
      }

      &:before,
      &:after {
        content: "";
        display: none;
        border-radius: 300px;
        border-width: 10px;
        border-color: #161414;
        border-style: solid;
        position: absolute;
        @media ${device.MXmd} {
          border-width: 5px;
        }
      }

      &:before {
        width: 300px;
        height: 300px;
        left: -75px;
        top: -75px;
        z-index: 1;

        @media ${device.MXmd} {
          width: 150px;
          height: 150px;
          left: -25px;
          top: -25px;
        }
      }
      &:after {
        width: 150px;
        height: 150px;
        border-radius: 300px;
        z-index: -1;
        right: -50px;
        bottom: -20px;
        z-index: 1;

        @media ${device.MXmd} {
          width: 75px;
          height: 75px;
          right: -25px;
          bottom: -10px;
        }        
      }
    }
    img {
      position: relative;
      z-index: 4;
    }
  }

  .hero-button-row {
    margin-top: 48px;
  }

  & .image-wrap .gatsby-image-wrapper > div {
    display: none;
  }
`


const StrategySession = ( data ) => {

  // console.log(data.data);

  const routeChange = (path) =>{  
    navigate(path);
  }

  return (
		<SectionComponent
			key={`strategy-${data.index}`}
			cssClass="section-strategy"
		>
			<div className="wrapper">
				<div className="inner-div">
					<div className="col-1">
						<div className="image-wrap">
							<LoadedImage
								altText={data.data.title}
								imgData={data.data.image.imageFile}
								className="responsive-image"
							/>
						</div>
					</div>
					<div className="col-2 c-relative">
						<h2>{data.data.title}</h2>
						<div className="ct-text-block">
							<div className="text">
								{data.data.text && ParseHTML(data.data.text)}
							</div>
						</div>
						<div className="hero-button-row">
							<LoadedButton
								cssClass="black"
								onClick={() => {
									routeChange(data.data.buttonLink)
								}}
								to={data.data.buttonLink}
							>
								{data.data.buttonText}
							</LoadedButton>
						</div>
					</div>
				</div>
			</div>
		</SectionComponent>
	)
}

export default StrategySession
