import React from "react"
import styled from "styled-components"
import ParseHTML from "../helpers/ParseHTML"
import {device} from "../theme/MediaQueries"

import { randomID } from "../helpers/helpers"

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  .review-card {
    flex-direction: column;
    display: flex;
    text-align: left;
    align-items: center;

    @media ${device.MXsm} {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 32px;
    }
  }

  .reviews-post > div{
    padding: 0 24px;
    @media ${device.MXmd} {
      padding: 0;
    }
  }

  .star-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
  }

  .white-bg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 24px;
    text-align: left;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    position: relative;
    box-shadow: 0 0.5px 1.5px -5px rgba(0, 0, 0, 0.022), 0 1.3px 3.8px -5px rgba(0, 0, 0, 0.031), 0 2.7px 7.7px -5px rgba(0, 0, 0, 0.039), 0 5.5px 15.9px -5px rgba(0, 0, 0, 0.048), 0 15px 46px -5px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
  }

  .rounded {
    border-radius: 8px;
  }

  .quote {
    display: flex;
    flex-direction: row;
    margin-right: 16px;
    transform: translate(-50%,-50%);
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .quote-bottom {
    transform: translate(50%,50%);
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .review-content {
    text-align: center;
    font-size: var(--font--1);
  }

  .review-by {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    align-items: center;
  }

  .review-name {
    text-align: center;
    font-size: var(--font-1);
    font-weight: 700;
    margin-bottom: 4px;
  }

  .review-title {
    text-align: center;
    font-size: var(--font--1);
    font-weight: 400;
    line-height: 1.2
  }

  .review-company {
    text-align: center;
    font-size: var(--font--1);
    font-weight: 700;
    line-height: 1.2
  }

`

const OpenQuote = (props) => {
  return (
    <svg 
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 22">
      <path style={{fill: props.fill}} d="M14.75,18v6a3,3,0,0,1-3,3h-6a3,3,0,0,1-3-3V13a8,8,0,0,1,8-8h1a1,1,0,0,1,1,1V8a1,1,0,0,1-1,1h-1a4,4,0,0,0-4,4v.5A1.5,1.5,0,0,0,8.25,15h3.5A3,3,0,0,1,14.75,18Zm14,0v6a3,3,0,0,1-3,3h-6a3,3,0,0,1-3-3V13a8,8,0,0,1,8-8h1a1,1,0,0,1,1,1V8a1,1,0,0,1-1,1h-1a4,4,0,0,0-4,4v.5a1.5,1.5,0,0,0,1.5,1.5h3.5A3,3,0,0,1,28.75,18Z" transform="translate(-2.75 -5)"/>
    </svg>
  )
}

const CloseQuote = (props) => {
  return (
    <svg 
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 22">
      <path style={{fill: props.fill}} d="M16.75,14V8a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3V19a8,8,0,0,1-8,8h-1a1,1,0,0,1-1-1V24a1,1,0,0,1,1-1h1a4,4,0,0,0,4-4v-.5a1.5,1.5,0,0,0-1.5-1.5h-3.5A3,3,0,0,1,16.75,14Zm-14,0V8a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3V19a8,8,0,0,1-8,8h-1a1,1,0,0,1-1-1V24a1,1,0,0,1,1-1h1a4,4,0,0,0,4-4v-.5A1.5,1.5,0,0,0,9.25,17H5.75A3,3,0,0,1,2.75,14Z" transform="translate(-2.75 -5)"/>
    </svg>
  )
}

const Star = (props) => {
  return (
    <svg 
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 22">
      <path style={{fill: props.fill}} d="M28.75,13.21a1.13,1.13,0,0,1-.41.75l-5.67,5.53L24,27.3c0,.11,0,.21,0,.32,0,.4-.18.78-.64.78a1.22,1.22,0,0,1-.62-.19l-7-3.69-7,3.69a1.28,1.28,0,0,1-.62.19.69.69,0,0,1-.66-.78c0-.11,0-.21,0-.32l1.35-7.81L3.14,14a1.2,1.2,0,0,1-.39-.75c0-.47.48-.65.87-.72l7.85-1.14L15,4.24c.14-.29.41-.64.77-.64s.62.35.77.64L20,11.35l7.85,1.14c.37.07.87.25.87.72Z" transform="translate(-2.75 -3.6)"/>
    </svg>
  )
}


const ReviewComponent = (props) => {
 
  const {loadedReview} = props;
  
  return (
    <div className="review-card">
      <div className="">
        <div className="star-wrap">
          <Star height="18" width="18" fill="#ff9e1b" /> 
          <Star height="18" width="18" fill="#ff9e1b" />
          <Star height="18" width="18" fill="#ff9e1b" />
          <Star height="18" width="18" fill="#ff9e1b" />
          <Star height="18" width="18" fill="#ff9e1b" />
        </div>
      </div>
      <div className="white-bg rounded drop-shadow-s">
        <div className="quote">
          <OpenQuote width="12px" height="12px" />
        </div>
        <div className="review-content">{ loadedReview.review && ParseHTML(loadedReview.review) }</div> 
        <div className="quote-bottom">
          <CloseQuote width="12px" height="12px" />
        </div>
      </div>
      <div className="review-by">
        <div className="review-name">{loadedReview.name}</div>
        <div className="review-company">{loadedReview.company}</div>
        <div className="review-title">{loadedReview.jobTitle}</div>
      </div>
    </div>
  )
}

const Reviews = ( data ) => {

  // console.log(data.data.loadedReviews);
  
  return (
    <SectionComponent key={`reviews-${data.index}`} cssClass={data.StrategySessionNext ? 'section-strategy-next' : 'section-bottom'}>
      <div className="inner-div wrapper reviews-post">
        <div className="c-columns-3 c-columns-gap-l c-columns-l-1">
          {data.data.loadedReviews.map((loadedReview, index) => (
            <ReviewComponent key={`review-${index}-${randomID()}`} loadedReview={loadedReview.review.review} />
          ))}
        </div>
      </div>
    </SectionComponent>
  )
}

export default Reviews
