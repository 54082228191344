import React from "react"
import { isEmptyObject, randomID } from "./helpers"

import Hero from "../blocks/Hero"
import Reviews from "../blocks/Reviews"
import Accordion from "../blocks/Accordion"
import SliderPost from "../blocks/SliderPost"
import StrategySession from "../blocks/StrategySession"
import SliderPartner from "../blocks/SliderPartner"
import HeroWithImage from "../blocks/HeroWithImage"
import HeroWithForm from "../blocks/HeroWithForm"
import ImageRightContentLeft from "../blocks/ImageRightContentLeft"
import ImageLeftContentRight from "../blocks/ImageLeftContentRight"
import FeatureImageRight from "../blocks/FeatureImageRightContentLeft"
import FeatureImageLeft from "../blocks/FeatureImageLeftContentRight"
import HubspotEmailCapture from "../blocks/HubspotEmailCapture"
import CTAWithImage from "../blocks/CTAWithImage"
import HubspotFormSection from "../blocks/HubspotFormSection"
import BlogCardSection from "../blocks/BlogCardSection"
import HeroHalfHalf from "../blocks/HeroHalfHalf"
import BrandSlider from "../blocks/BrandSlider"
import ImageSection from "../blocks/ImageSection"
import ImageVideoSection from "../blocks/ImageVideoSection"
import DoubleList from "../blocks/DoubleList"
import ImageVideoCircleSection from "../blocks/ImageVideoCircleSection"
import FindOutMore from "../blocks/FindOutMore"
import ParseHTML from "./ParseHTML"

import contentParser from 'gatsby-source-wpgraphql-images'

import { pluginOptions } from "../helpers/helpers"

const wordPressUrl = pluginOptions.wordPressUrl
const uploadsUrl = pluginOptions.uploadsUrl

const components = {
  "acf/reviews": Reviews,
  "acf/accordion": Accordion,
  "acf/hero-section": Hero,
  "acf/slider-post": SliderPost,
  "acf/strategy-session": StrategySession,
  "acf/slider-partner": SliderPartner,
  "acf/image-hero-section": HeroWithImage,
  "acf/hubspot-email-capture": HubspotEmailCapture,
  "acf/image-right-content-left": ImageRightContentLeft,
  "acf/image-left-content-right": ImageLeftContentRight,  
  "acf/feature-image-right": FeatureImageRight,
  "acf/feature-image-left": FeatureImageLeft,
  "acf/hero-form-section": HeroWithForm,
  "acf/image-cta-section": CTAWithImage,
  "acf/hubspot-form-section": HubspotFormSection,
  "acf/blog-card-section": BlogCardSection,
  "acf/hero-half-half-section": HeroHalfHalf,
  "acf/brand-slider": BrandSlider,
  "acf/image-section": ImageSection,
  "acf/image-video-section": ImageVideoSection,
  "acf/double-list": DoubleList,
  "acf/image-video-circle-section": ImageVideoCircleSection,
  "acf/find-out-more": FindOutMore,
}

const convertACFProps = component => {
  // get all object keys and iterate over them
  Object.keys(component).forEach(key => {
    //console.log(key);
    if (key.includes("Fields")) {
      component.data = component[key]
      //console.log(component[key]);

      delete component[key]
    }
  })

  return component
}

const ComponentParser = props => {
  let { content, contentParsed, contentFiles } = props

  //console.log(content);
  if (!content) return null
  const filteredComponents = content.filter(
    component =>
      !component || !isEmptyObject(component) || component.name !== null
  )

  if (filteredComponents && filteredComponents.length > 0) {
    const pageComponents = filteredComponents.map((component, index) => {
      const Component = components[component.name]

      if (!Component)  {
          return (component.originalContent !== "") ? <section className="section-bottom" key={`component-${randomID()}`}><div className="inner-div"><div >{contentParser({ content: contentParsed, files: contentFiles }, { wordPressUrl, uploadsUrl })}</div></div></section> : <section className="section-bottom" key={`component-${randomID()}`}><div className="inner-div"><div >{ component.dynamicContent && ParseHTML(component.dynamicContent) }</div></div></section>;
      }

      if (filteredComponents[index+1] && (filteredComponents[index+1].name === 'acf/strategy-session')) {
        component.StrategySessionNext = true;
      }

      //console.log(component);
      component = convertACFProps(component)

      return (
        <Component
          index={index}
          key={`component-${randomID()}`}
          {...component}
          {...component.fields}
          {...component.attributes}
        />
      )
    })

    if (pageComponents) {
      return pageComponents
    }
  }
  return null
}

export default ComponentParser
