import React from "react"
import styled from "styled-components"

import ParseHTML from "../helpers/ParseHTML"

import ThemeDefault from "../theme/ThemeDefault";
import {device} from "../theme/MediaQueries"

import { GatsbyImage } from "gatsby-plugin-image"

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
	background-color: ${(props) => props.bgColour};

	.image-wrapper {
		box-shadow: 0 8px 16px -8px rgba(0,0,0,0.5);
		border-radius: 2.4rem;
		overflow: hidden;
	}
  
` 

const ImageSection = ( data ) => {
  
  //console.log(data);
  const {childImageSharp} = data.data.image.imageFile;

  return (
    <SectionComponent key={`slider-${data.index}`} bgColour={data.data.sectionBgColour} >
      <div className="inner-div wrapper">
				<div className="image-wrapper">
					<GatsbyImage alt={data.data.altText ? data.data.altText : 'Loaded Reports'} image={childImageSharp.gatsbyImageData} className={data.data.className} />
				</div>
			</div>
    </SectionComponent>
  )
}

export default ImageSection
