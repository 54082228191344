import React, { useState } from "react"
import styled from "styled-components"

import ThemeDefault from "../theme/ThemeDefault";
import {navigate} from 'gatsby'; //import navigate from gatsby

import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from 'react-player/lazy'
import device from "../theme/MediaQueries";
import { ParseAndRemoveTags } from "../helpers/ParseHTML";

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

	h2 {
		font-family: ${ThemeDefault.fontSecondary};
		font-size: ${ThemeDefault.font_7};
	}
  .wrapper {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;

      & .title-col {
        align-items: center;
      }
      & h1  {
        text-align: center;
        & *  {
          text-align: center;
        }
      }
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.4;
      letter-spacing: -1px;
      margin-bottom: 32px;
      text-align: left;
      font-weight: 500;
      
      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }

    .title-col {
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
      width: 50%;

      @media ${device.MXmd} {
        padding: 24px 0;
        width: 50%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
      @media ${device.MXxs} {
        width: 100%;
      }
    }

    .hero-text {
      text-align: left;
      margin-bottom: 32px;
      font-size: var(--font-2);
      padding-right: 64px;

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .button-text {
      text-align: left;
      font-weight: 700;
      color: ${ThemeDefault.blue};

      @media ${device.MXmd} {
        width: 100%;
        margin-top: 8px;
      }
    }

    .hero-button-row {
      flex-direction: row;
      display: flex;
      align-items: center;
      
      @media ${device.MXmd} {
        flex-wrap: wrap;
      }
      
      p {
        margin: 0;
      }
      
    }

    .circle picture,
    .circle img,
    .circle .gatsby-image-wrapper {
      overflow: hidden;
      border-radius: 50% !important;
    }

    .circle .gatsby-image-wrapper {
      margin-left: auto;

      @media ${device.MXmd} {
        width: 100%;
        margin: 0 auto;
      }

      @media ${device.MXsm} {
        margin: 0 auto;
      }
    }

    .image-col {
      width: 50%;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      
      @media ${device.MXmd} {
        width: 50%;
      }

      @media ${device.MXsm} {
        width: 100%;
      }

      @media ${device.MXxs} {
        width: 100%;
      }

      & .image-inner {
        position: relative;
        width: 100%;
        padding-bottom: 100%;

        &>div {
          position: absolute;
        }
        .player {
          width: 100%;
          height: 100%;
        }
      }
    }
    .youtube-wrap .lightBoxLink,
    .youtube-wrap a {
      color: ${ThemeDefault.black};
      display: flex; 
      flex-direction: row;
      align-items: center;
			margin-top: 40px;
      font-weight: 600;
      font-size:  ${ThemeDefault.font_2};

      @media ${device.MXsm} {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
    
    .lightBoxLink {
      cursor: pointer;
    }

    .play-icon {
      display: inline-block;
      width: 50px;
      height: 50px;
      background: #d4aab5;
      position: relative;
      border-radius: 50%;
      margin-right: 8px;

			&.reseller-program {
				background: ${ThemeDefault.blue};
				&:before {
					border-color: transparent transparent transparent ${ThemeDefault.white}
				}
			}

      &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 24px;
        left: 58%;
        top: 50%;
        transform: translate( -50%, -50%);
        border-style: solid;
        border-color: transparent transparent transparent #202020;
        border-width: 12px 0px 12px 18px;
      }
    }
  }
  
  .loadedLightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.0);
    z-index: 9999999;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    transition: 400ms;
    cursor: pointer;

    &.open {
      background: rgba(0, 0, 0, 0.85);
    }

    & .closeButton {
      position: absolute;
      top: 16px;
      right: 16px;
      color: #000;
      background: #fff;
      line-height: 1;
      height: 34px;
      width: 34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      padding-top: 3px;
      font-weight: 700; 
    }
    & .lightboxInner {
      width: 90%;
      max-width: 1200px;
      max-height: 80vh;
      height: 80%;
      & > div {
        width: 100% !important;
        height: 100% !important;
        position: relative !important;
        & > div {
          position: absolute !important;

          & iframe {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }

	.image {
		width: 70%;
		padding-bottom: 70%;
		border-radius: 100%;
		overflow: hidden;
		position: relative;
		margin: 0 auto;

		& > div {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

` 


const ImageVideoCircleSection = ( data ) => {
  
	const [showVideo, setShowVideo] = useState(false)
  const routeChange = (path) =>{  
    navigate(path);
  }
  
  // const { openLightbox } = useLightbox()
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
  const [isLightBoxOpenClass, setIsLightBoxOpenClass] = useState('')

  const handleKeyDown = (ev, index) => {
    // check keys if you want
    if (ev.keyCode === 13) {
      setIsLightBoxOpen(true)
    }

  }

  const openVidLightbox = () => {
    setIsLightBoxOpen(true)
    
    setTimeout(()=>{
      setIsLightBoxOpenClass('open')
    }, 300)

    document.querySelector('body').style.overflowY = 'hidden';
  }
  const closeVidLightbox = () => {
    setIsLightBoxOpen(false)
    setIsLightBoxOpenClass('close')
    document.querySelector('body').style.overflowY = 'auto';
  }

  const {childImageSharp, publicURL} = data.data.image.imageFile;

  return (
    <SectionComponent key={`slider-${data.index}`} bgColour={data.data.sectionBgColour} >
			<div className="inner-div wrapper">
				<div className={`image-col`}>
					<div className={`image ${childImageSharp && "circle"}`}>
						{
							childImageSharp ? 
							<GatsbyImage alt={data.data.altText ? data.data.altText : data.data.sectionText.replace(/(<([^>]+)>)/gi, "")} image={childImageSharp.gatsbyImageData} className={data.data.className} /> :
							<img alt={data.data.altText ? data.data.altText : data.data.sectionText.replace(/(<([^>]+)>)/gi, "")} src={publicURL} className={data.data.className} /> 
						}
					</div>
					<div className="youtube-wrap">
						<div className="lightBoxLink" tabIndex={0} onKeyDown={(ev) => handleKeyDown(ev, 1)} onClick={openVidLightbox} role="button"><span className={`${typeof window !== 'undefined' ? window.location.pathname.replaceAll('/', '') : ''} play-icon`}></span> Watch The Video</div>
					</div>
				</div>
				<div className="title-col">
					<div className="hero-title"><h2>{data.data.sectionText && ParseAndRemoveTags(data.data.sectionText)}</h2></div>			
				</div>
			</div>
			{isLightBoxOpen && (
				<div className={`loadedLightbox ${isLightBoxOpenClass}`} onClick={closeVidLightbox}>
					<div className="closeButton" onClick={closeVidLightbox}>X</div>
					<div className="lightboxInner">
						<ReactPlayer url={ data.data.videoLink} controls={true} playsinline={true} />
					</div>
				</div>
			)}

    </SectionComponent>
  )
}

export default ImageVideoCircleSection

