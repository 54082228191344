import React, { useRef, useState } from "react"
import styled from "styled-components"

import ThemeDefault from "../theme/ThemeDefault";
import {device} from "../theme/MediaQueries";

import contentParser from 'gatsby-source-wpgraphql-images'

import { pluginOptions, randomID } from "../helpers/helpers"

import ParseHTML from "../helpers/ParseHTML"

const wordPressUrl = pluginOptions.wordPressUrl
const uploadsUrl = pluginOptions.uploadsUrl

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  h2 {
    font-family: ${ThemeDefault.fontSecondary};
    font-weight: 400;
  }

  .col-1 {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 32px;
  }

  .col-2 {

  }

  .accordion {
    width: 100%;
    background: ${ThemeDefault.white};
    color: ${ThemeDefault.black};
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: 0.4s ease;
    padding: 10px 0;
    border-bottom: 1px solid ${ThemeDefault.greyLight};
    box-shadow: none;

    p {
      margin: 0;
      padding: 0;
    }

    :hover {
      border-bottom: 1px solid ${ThemeDefault.greyDark};
      .icon {
        transform: rotate(-45deg);
      }
    }

    &.active {
      border-bottom: 1px solid ${ThemeDefault.greyDark};
      :hover .icon {
        transform: rotate(-360deg);
      }
    }
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active {
    box-shadow: none;
  }

  /* Style the accordion content title */
  .accordion__title {
    font-weight: 400;
    /* text-transform: uppercase; */
    font-size: ${ThemeDefault.font_2};
    @media ${device.MXlg} {
      font-size: ${ThemeDefault.font_1};
    }
    @media ${device.MXsm} {
      font-size: ${ThemeDefault.font_0};
    }
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.4s ease;
    transform: rotate(45deg);
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(360deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    overflow: hidden;
    transition: max-height 0.6s ease;

    &.active{
      border-bottom: 1px solid ${ThemeDefault.greyDark};
    }
  }

  /* Style the accordion content text */
  .accordion__text {
    padding: 16px;
  }

  .icon {
    padding: 2px;
    flex-shrink: 0;
  }
`

const Chevron = (props) => {

  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.12 30.12">
      <path d="M15.75.94A15.06,15.06,0,1,0,30.81,16,15.06,15.06,0,0,0,15.75.94Zm0,29.12A14.06,14.06,0,1,1,29.81,16,14.05,14.05,0,0,1,15.75,30.06Zm8.13-22.9L15.75,15.3,7.61,7.16l-.71.71L15,16,6.9,24.14l.71.71,8.14-8.14,8.13,8.14.71-.71L16.45,16l8.14-8.13Z" transform="translate(-0.69 -0.94)"/>
    </svg>
  );
 }
 

const AccordionComponent = (props) => {

  const { tab, index } = props;

  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const handleKeyDown = (ev) => {
    // check keys if you want
    if (ev.keyCode === 13) {
      toggleAccordion()
    }
  }

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "");

    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );

    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  const content = useRef(null);

  const contentFiles = tab.content.contentFiles;
  const contentParsed = tab.content.contentParsed;

  return (
    <div className="accordion__section" >
      <div role="button" tabIndex={index} className={`accordion ${setActive}`} onClick={toggleAccordion} onKeyDown={handleKeyDown} >
        <p className="accordion__title">{tab.content.title}</p>
        <Chevron className={`icon ${setRotate}`} width={32} stroke={ThemeDefault.black} />
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={`accordion__content ${setActive}`}
      >
        <div className="accordion__text"> {contentParser({ content: contentParsed, files: contentFiles }, { wordPressUrl, uploadsUrl })} </div>
      </div>
    </div>
  );
}

const Accordion = ( data ) => {

  // console.log(data.data.tabs);
  
  return (
    <SectionComponent key={`slider-${data.index}`} cssClass={data.StrategySessionNext ? 'section-strategy-next' : 'section-bottom'}>
      <div className="inner-div wrapper accordion-post">
        <div className="c-columns-3 c-columns-gap-xs c-columns-l-1">
          <div className="col-1 c-relative">
            <h2>{data.data.title}</h2>
            <div className="ct-text-block">
              <div className="text">{ ParseHTML(data.data.text) }</div>
            </div>
          </div>
          <div className="col-2 c-col-span-2 acccordion-wrapper">
            <div >
              {data.data.tabs.map((tab, index) => (
                <AccordionComponent index={index} key={`accordion-${index}-${randomID()}`} tab={tab} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </SectionComponent>
  )
}

export default Accordion
