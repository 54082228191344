import React from "react"
import styled from "styled-components"

import ParseHTML from "../helpers/ParseHTML"

import ThemeDefault from "../theme/ThemeDefault";
import {device} from "../theme/MediaQueries"

import { GatsbyImage } from "gatsby-plugin-image"

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  .col-1 {

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;

  }

  .col-2 {
    padding: 24px;

    @media ${device.MXxs} {
      padding: 0;
    }

    & .text-block {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    & h2 {
      font-family: ${ThemeDefault.fontSecondary};
      font-size: ${ThemeDefault.font_3};
      line-height: 1.3;
    }

  }

  .image-wrapper {
    width: 100%;
    height: 100%;

    @media ${device.MXmd} {
      height: auto;
      padding-bottom: 100%;

      & .gatsby-image-wrapper {
        position: absolute !important;
      }
    } 

  }

  .column-image .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  .c-reverse-m .col-1 {
    @media ${device.MXsm} {
      grid-row-start: 2;
    }
  }
` 

const ImageLeftContentRight = ( data ) => {
  
  //console.log(data);
  const {childImageSharp} = data.data.image.imageFile;

  return (
    <SectionComponent key={`slider-${data.index}`} >
      <div className="inner-div wrapper slider-post">
        <div className="c-columns-2 c-columns-gap-xs c-columns-m-1 c-reverse-m">
          <div className="col-1 c-relative column-image">
            <div className="image-wrapper">
              <GatsbyImage alt={data.data.altText ? data.data.altText : 'Loaded Reports'} image={childImageSharp.gatsbyImageData} className={data.data.className} />
            </div>
          </div>
          <div className="col-2">
            <div className="text-block block-1">
              <h2>{ data.data.title1 && ParseHTML(data.data.title1) }</h2>
              <div className="ct-text-block">
                <div className="text">{ data.data.text1 && ParseHTML(data.data.text1) }</div>
              </div>
            </div>
            <div className="text-block block-2">
              <h2>{ data.data.title2 && ParseHTML(data.data.title2) }</h2>
              <div className="ct-text-block">
                <div className="text">{ data.data.text2 && ParseHTML(data.data.text2) }</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionComponent>
  )
}

export default ImageLeftContentRight
